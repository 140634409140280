import { FormArray, FormControl, FormGroup } from '@angular/forms'
import { environment } from '@env/environment'
import { CustomFormControlService } from '@services/custom-form-control.service'
import currency from 'currency.js'
import moment from 'moment'
import Swal from 'sweetalert2'

export const getTimeZone = () => {
	let timezone: any = {
		id: 7,
		sg: 8,
		jp: 9,
	}

	let country = localStorage.getItem('country') || 'sg'

	return timezone[country]
}

export const convertPricing = (value: any, ...args: any) => {
	let extra = args?.[0] || {}

	if (isEmpty(value)) {
		value = 0
	}

	let format: any = {
		separator: ',',
		symbol: '',
		fromCents: false,
		precision: 2,
	}

	if (!extra?.removePrefix) {
		format.symbol = 'SGD '
	}

	if (!extra?.removeSeparator) {
		format.separator = ''
	}

	let result: any = currency(value, format).format()

	if (extra.toNumber) {
		result = +result
	}

	return result
}

export const isEmpty = (value: any) => {
	if (value === null || value === undefined) {
		return true
	}

	if (Array.isArray(value) || typeof value === 'string') {
		if (value === '[]') {
			return true
		}
		return value.length === 0
	}

	if (typeof value === 'number') {
		return false // Numbers are never considered empty
	}

	if (typeof value === 'object') {
		if (value instanceof File) {
			return value.size === 0 // Consider the file "empty" if its size is 0
		}
		return Object.keys(value).length === 0
	}

	return false
}

export const convertToBase64 = async (file: File) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () =>
			resolve({
				base64: reader.result,
				file: file,
			})
		reader.onerror = (error) => reject(error)
	})
}

export const resizeImage = async (file: File) => {
	if (isEmpty(file)) {
		let reason = 'User Abort'
		return { status: 'fail', reason: reason }
	}

	if (file.size / 1000000 > 8) {
		let reason = 'The image size must be less than 8MB'
		Swal.fire({
			text: reason,
			icon: 'error',
			buttonsStyling: false,
			confirmButtonText: 'Ok',
			customClass: {
				confirmButton: 'btn btn-primary',
			},
		})
		return { status: 'fail', reason: reason }
	}

	if (!file.type.includes('image')) {
		let reason = 'File is not an image'
		Swal.fire({
			text: reason,
			icon: 'error',
			buttonsStyling: false,
			confirmButtonText: 'Ok',
			customClass: {
				confirmButton: 'btn btn-primary',
			},
		})
		return { status: 'fail', reason: reason }
	}

	let reader = new FileReader()
	reader.readAsDataURL(file)

	const resize = async (imageURL: string, fileType: string, maxDimension: number = 800): Promise<string> => {
		const image = new Image()
		image.src = imageURL
		await new Promise((resolve, reject) => {
			image.onload = resolve
			image.onerror = reject
		})

		let width = image.width
		let height = image.height

		if (width > maxDimension || height > maxDimension) {
			const scaleFactor = maxDimension / Math.max(image.width, image.height)
			width = image.width * scaleFactor
			height = image.height * scaleFactor
		}

		const canvas = document.createElement('canvas')
		canvas.width = width
		canvas.height = height
		const ctx = canvas.getContext('2d')!

		if ('createImageBitmap' in window) {
			let imageBitmap = await createImageBitmap(image, 0, 0, image.width, image.height, {
				resizeWidth: width,
				resizeHeight: height,
				resizeQuality: 'high',
			})

			ctx.drawImage(imageBitmap, 0, 0, width, height)
		} else {
			ctx.drawImage(image, 0, 0, width, height)
		}

		return canvas.toDataURL(fileType, 0.8)
	}

	let result = await new Promise((resolve) => {
		reader.onload = async () => {
			await resize(reader.result as string, file.type).then((base64: any) => {
				resolve(base64)
			})
		}
	})

	return { status: 'success', base64: result, file: file }
}

export const getStatusBadge = (status: string | number, lightBadge: boolean = true, key: string | null = null) => {
	const allStatuses: Record<any, any> = {
		users: {
			0: 'PENDING_VERIFICATION',
			1: 'VERIFIED',
			2: 'PENDING_DEPOSIT',
			3: 'DEPOSITED',
			4: 'REJECTED',
			5: 'BLOCKED',
			6: 'ACCOUNT_CLOSED',
			7: 'PENDING_CLOSE_ACCOUNT',
		},
		reauths: {
			0: 'PENDING',
			1: 'APPROVED',
			2: 'REJECTED',
			3: 'PENDING APPROVAL',
			4: 'VOID',
			5: 'PARTIALLY REFUNDED',
			6: 'REFUNDED',
			7: 'DELETED',
		},
		refunds: {
			0: 'PENDING APPROVAL',
			1: 'APPROVED',
			2: 'REJECTED',
		},
		orders: {
			0: 'RESERVED',
			1: 'UPCOMING',
			2: 'ONGOING',
			3: 'CANCELLED',
			4: 'COMPLETED',
			5: 'RESERVE_CANCELLED',
		},
		trackers: {
			0: 'OFFLINE',
			1: 'ONLINE',
		},
		vehicles: {
			0: 'INACTIVE',
			1: 'ACTIVE',
		},
		fleets: {
			0: 'Failed',
			1: 'SUCCESS',
		},
		popups: {
			0: 'Disable',
			1: 'Enable',
		},
		transactions: {
			1: 'Approved',
			2: 'Settled',
			3: 'Refunded',
		},
		feedbacks: {
			0: 'New',
			1: 'Replied',
		},
		tasks: {
			0: 'New',
			1: 'Processing',
			2: 'Completed',
			3: 'Hided',
		},
		companies: {
			0: 'Pending',
			1: 'Approved',
			2: 'Rejected',
		},
		['debt-collections']: {
			0: 'Pending',
			1: 'Approved',
			2: 'Failed',
		},
		['vehicle-deployments']: {
			0: 'Pending',
			1: 'Approved',
			2: 'Rejected',
			3: 'Deployed',
		},
		settlements: {
			0: 'Pending',
			1: 'Processed',
			2: 'Agreed',
		},
		['groomer-tasks']: {
			pending: 'PENDING',
			ended: 'ENDED',
			cancelled: 'CANCELLED',
			ongoing: 'ONGOING',
		},
		['groomer-task-vehicles']: {
			pending: 'PENDING',
			skipped: 'SKIPPED',
			start: 'START',
			done: 'DONE',
			rest: 'REST',
		},
	}

	if (typeof status == 'number' && key) {
		if (allStatuses[key][status]) {
			status = allStatuses[key][status]
		} else {
			status = 'Unknown'
		}
	}

	if (typeof status == 'string') {
		status = status.toUpperCase()
	}

	const statuses: Record<string, string> = {
		//success
		APPROVE: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Approve</span>`,
		APPROVED: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Approved</span>`,
		RESERVED: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Reserved</span>`,
		CONNECTED: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Connected</span>`,
		ACTIVE: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Activate</span>`,
		COMPLETED: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Completed</span>`,
		VERIFIED: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Verified</span>`,
		DEPOSITED: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">DEPOSITED</span>`,
		ONLINE: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Online</span>`,
		SUCCESS: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Success</span>`,
		ENABLE: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Enable</span>`,
		REFUNDED: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Refunded</span>`,
		SETTLED: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Settled</span>`,
		ASSIGNED: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Assigned</span>`,
		DEPLOYED: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Deployed</span>`,
		PROCESSED: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Processed</span>`,
		DONE: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Done</span>`,
		START: `<span class="badge badge-${lightBadge ? 'light-' : ''}success">Start</span>`,

		//warning
		ONGOING: `<span class="badge badge-${lightBadge ? 'light-' : ''}warning">On-going</span>`,
		AGREED: `<span class="badge badge-${lightBadge ? 'light-' : ''}warning">Agreed</span>`,
		UPCOMING: `<span class="badge badge-${lightBadge ? 'light-' : ''}warning">Upcoming</span>`,
		SKIPPED: `<span class="badge badge-${lightBadge ? 'light-' : ''}warning">Skipped</span>`,
		['PARTIALLY REFUNDED']: `<span class="badge badge-${lightBadge ? 'light-' : ''}warning">Partially Refunded</span>`,

		//secondory
		VOID: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">Void</span>`,
		REST: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">Rest</span>`,
		REPLIED: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">Replied</span>`,
		PENDING: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">Pending</span>`,
		CANCELLED: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">Cancelled</span>`,
		PENDING_DEPOSIT: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">Pending Deposit</span>`,
		PENDING_VERIFICATION: `<span class="badge badge-${
			lightBadge ? 'light-' : ''
		}secondary">Pending Verification</span>`,
		['PENDING APPROVAL']: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">Pending Approval</span>`,
		['PENDING REFUND']: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">Pending Refund</span>`,
		PENDING_CLOSE_ACCOUNT: `<span class="badge badge-${
			lightBadge ? 'light-' : ''
		}secondary">Pending Close Account</span>`,
		NEW: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">New</span>`,
		PROCESSING: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">Processing</span>`,
		NA: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">N/A</span>`,
		ENDED: `<span class="badge badge-${lightBadge ? 'light-' : ''}secondary">Ended</span>`,

		//danger
		RESERVE_CANCELLED: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Reserve Cancelled</span>`,
		REJECTED: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Rejected</span>`,
		DELETED: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Deleted</span>`,
		REJECT: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">REJECT</span>`,
		BLOCKED: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Blocked</span>`,
		ACCOUNT_CLOSED: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Account Closed</span>`,
		OFFLINE: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Offline</span>`,
		INACTIVE: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Inactive</span>`,
		FAILED: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Failed</span>`,
		DISABLE: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Disable</span>`,
		LATE: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Late</span>`,
		['REAUTH FAIL']: `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Reauth Fail</span>`,
	}

	return statuses[status] || `<span class="badge badge-${lightBadge ? 'light-' : ''}danger">Unknown</span>`
}

export const ucfirst = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1)
}

export function isDateTimeFormat(dateStr: string): boolean {
	if (moment(dateStr, 'YYYY-MM-DD HH:mm', true).isValid()) {
		return true
	} else if (moment(dateStr, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
		return true
	} else if (moment(dateStr, 'YYYY-MM-DD', true).isValid()) {
		return true
	}

	if (dateStr.includes(' to ')) {
		let dateData = dateStr.split(' to ')

		return isDateTimeFormat(dateData[0]) && isDateTimeFormat(dateData[1])
	}

	return false
}

export function formatPayload(obj: any, method: 'subtract' | 'add'): any {
	let timezone = getTimeZone()

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			if (typeof obj[key] === 'string' && isDateTimeFormat(obj[key])) {
				let utcDate

				if (method == 'subtract') {
					if (obj[key].includes(' to ')) {
						let dateData = obj[key].split(' to ')

						if (dateData.length == 2) {
							let date1 = moment.utc(dateData[0]).subtract(timezone, 'hours').format('YYYY-MM-DD HH:mm:ss')
							let date2 = moment.utc(dateData[1]).subtract(timezone, 'hours').format('YYYY-MM-DD HH:mm:ss')

							utcDate = `${date1} to ${date2}`
						}
					} else {
						utcDate = moment.utc(obj[key]).subtract(timezone, 'hours').format('YYYY-MM-DD HH:mm:ss')
					}
				} else {
					if (obj[key].includes(' to ')) {
						let dateData = obj[key].split(' to ')

						if (dateData.length == 2) {
							let date1 = moment.utc(dateData[0]).add(timezone, 'hours').format('YYYY-MM-DD HH:mm:ss')
							let date2 = moment.utc(dateData[1]).add(timezone, 'hours').format('YYYY-MM-DD HH:mm:ss')

							utcDate = `${date1} to ${date2}`
						}
					} else {
						utcDate = moment.utc(obj[key]).add(timezone, 'hours').format('YYYY-MM-DD HH:mm:ss')
					}
				}
				obj[key] = utcDate
			} else if (typeof obj[key] === 'object' && obj[key] !== null) {
				formatPayload(obj[key], method)
			}
		}
	}
	return obj
}

export function getDuration(fromDateStr: string, toDateStr: string, graceMinute: number | null = null): string {
	const fromDate = new Date(fromDateStr)
	const toDate = new Date(toDateStr)

	if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
		return 'Invalid date format'
	}

	if (graceMinute) {
		toDate.setTime(toDate.getTime() + graceMinute * 60 * 1000)
	}

	let durationMs = Math.abs(toDate.getTime() - fromDate.getTime())

	const msPerMinute = 60 * 1000
	const msPerHour = msPerMinute * 60
	const msPerDay = msPerHour * 24

	const days = Math.floor(durationMs / msPerDay)
	durationMs -= days * msPerDay

	const hours = Math.floor(durationMs / msPerHour)
	durationMs -= hours * msPerHour

	const minutes = Math.floor(durationMs / msPerMinute)

	return `${days}d ${hours}hr ${minutes}min`
}

export function setErrors(errResponse: any, form: FormGroup) {
	if (errResponse && errResponse.error) {
		const validationErrors = errResponse.error.errors
		if (validationErrors) {
			Object.keys(validationErrors).forEach((prop) => {
				const formControl = form.get(prop)

				if (formControl) {
					formControl.setErrors(null)
					formControl.setErrors({
						serverError: validationErrors[prop],
					})
					formControl.markAsTouched({ onlySelf: true })
					formControl.markAsDirty({ onlySelf: true })
				}
			})
		}
	}
}

export function clearErrors(formGroup: FormGroup | FormArray): void {
	Object.keys(formGroup.controls).forEach((key) => {
		const control = formGroup.get(key)

		if (control instanceof FormGroup || control instanceof FormArray) {
			clearErrors(control)
		} else {
			control?.setErrors(null)
		}
	})
}

export function validateForm(formGroup: FormGroup, customFormControlService: CustomFormControlService) {
	Object.keys(formGroup.controls).forEach((field) => {
		const control = formGroup.get(field)
		if (control instanceof FormControl) {
			control.markAsTouched({ onlySelf: true })
			control.markAsDirty({ onlySelf: true })

			const component = customFormControlService.getComponent(control)
			if (component) {
				component.checkFormIsValid()
			}
		} else if (control instanceof FormGroup) {
			validateForm(control, customFormControlService)
		}
	})
}

export function formatPrice(str: string) {
	let result = str.replace(/[^0-9.]/g, '')

	if ((result.match(/\./g) || []).length > 1) {
		let firstDotIndex = result.indexOf('.')
		result = result.substr(0, firstDotIndex + 1) + result.substr(firstDotIndex + 1).replace(/\./g, '')
	}

	const parts = result.split('.')
	if (parts[1] && parts[1].length > 2) {
		parts[1] = parts[1].substr(0, 2)
		result = parts.join('.')
	}

	return result
}

export function getImage(pathToFile: string) {
	return `${pathToFile}`
}

export function isEqual(obj1: any, obj2: any) {
	if (obj1 === obj2) {
		return true
	}

	if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
		return false
	}

	let keys1 = Object.keys(obj1)
	let keys2 = Object.keys(obj2)

	if (keys1.length !== keys2.length) {
		return false
	}

	for (let key of keys1) {
		if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
			return false
		}
	}

	return true
}

export function getPlatform() {
	let domainKey = 'shariotPlatform'
	let platform: any = localStorage.getItem(domainKey)

	if (!isEmpty) {
		return platform
	}

	if (location.hostname == environment.adminDomain) {
		platform = 'admin'
	} else if (location.hostname == environment.dealerDomain) {
		platform = 'dealer'
	}
	return platform
}

export function convertISOToDateTimeFormat(isoString: string) {
	// Create a Date object from the ISO string
	const date = new Date(isoString)

	// Format the date parts
	const year = date.getUTCFullYear()
	const month = String(date.getUTCMonth() + 1).padStart(2, '0') // getUTCMonth() is zero-indexed
	const day = String(date.getUTCDate()).padStart(2, '0')

	// Format the time parts
	const hours = String(date.getUTCHours()).padStart(2, '0')
	const minutes = String(date.getUTCMinutes()).padStart(2, '0')
	const seconds = String(date.getUTCSeconds()).padStart(2, '0')

	// Construct the custom formatted string
	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export function getCookie(name: string) {
	let cookies = document.cookie // Get all cookies as a single string
	let cookieArray = cookies.split('; ') // Split it into an array based on '; ' delimiter

	for (let cookie of cookieArray) {
		let [cookieName, cookieValue] = cookie.split('=') // Split each cookie into name and value
		if (cookieName === name) {
			return decodeURIComponent(cookieValue) // Return the value of the requested cookie
		}
	}
	return ''
}

export function getQueryParams(name?: string) {
	let params: any = {}
	let queryString = window.location.search

	if (queryString) {
		queryString
			.substring(1)
			.split('&')
			.forEach((param) => {
				let [key, value] = param.split('=')
				params[decodeURIComponent(key)] = decodeURIComponent(value || '')
			})
	}

	if (name) {
		return params[name]
	}

	return params
}
